import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GetArticleById from '../services/GetArticleById';
import GetArticleImage from '../services/GetArticleImage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Banner from './Banner';
import Content from './Content';
import { Container, Typography, Link, CssBaseline } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';
import GetUserDetails from '../services/GetUserData';
import FetchProfileImage from '../services/FetchProfileImage';

function Travel() {
  const { id: articleId } = useParams();
  const [article, setArticle] = useState(null);
  const [profileImage, setProfileImage] = useState('');
  const [articleImageKey, setArticleImageKey] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const articleData = await GetArticleById(articleId);
        const imageUrl = await GetArticleImage(articleData.articleImageKey);
        const authorDetails = await GetUserDetails(articleData.author)
        console.log(authorDetails.profileImageKey)
        const authorProfileImage = await FetchProfileImage(authorDetails.profileImageKey)
        setProfileImage(authorProfileImage)
        setArticleImageKey(imageUrl);
        setArticle(articleData);
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    fetchArticle();
  }, [articleId]);
  return (
    <div>
      <CssBaseline />
      <Banner articleImageKey={articleImageKey} />
      <Container maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb" >
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href="/travel"
          >
            <ArticleIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Travel
          </Link>
          <Typography
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
          >
            {article?.title}
          </Typography>
        </Breadcrumbs>
      </Container>
      {article && <Content article={article} profileImage={profileImage} articleImageKey={articleImageKey} />}
    </div>
  );
}

export default Travel;
