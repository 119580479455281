import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import GetAdminData from '../services/GetAdminData'
import GetAdminImage from '../services/GetAdminImage'

function TravelGridBanner() {

    const [travelCoverImage, setTravelCoverImage] = useState();

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const adminData = await GetAdminData();
                const travelCoverImageImageUrl = await GetAdminImage(adminData.travelCoverImage);
                setTravelCoverImage(travelCoverImageImageUrl);
            } catch (error) {
                console.error("Error occurred when getting data", error);
            }
        };
        fetchAdminData();
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                height: {
                    xs: '200px',
                    sm: '300px',
                    md: '400px',
                    lg: '500px',
                },
                width: '100%',
            }}
        >
            <Box
                component="img"
                sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                src={travelCoverImage}
                alt="Banner Image"
            />
        </Box>
    );
}

export default TravelGridBanner;
