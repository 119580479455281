import { Box, Button, Container, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles/input.css';
import AddComment from '../services/AddComment';
import GetUserDetails from '../services/GetUserData';

function CommentBox({ articleId }) {
  const [user, setUser] = useState([]);
  const [comment, setComment] = useState("");
  const [nickname, setNickname] = useState("");
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    const data = sessionStorage.getItem('user');
    if (data) {
      const parsedData = JSON.parse(data);
      const userDetails = parsedData?.UserAttributes || [];
      setUser(userDetails);
      const nick = userDetails.find(attr => attr.Name === "nickname")?.Value;
      fetchUserDetails(nick)
      setNickname(nick || "");
    }
  }, []);

  const fetchUserDetails = async (nickname) => {
    try {
      const user = await GetUserDetails(nickname);
      const profileImage = user.profileImageKey;
      console.log(profileImage)
      setProfileImage(profileImage)
    } catch (error) {
      console.error("Error occurred when getting user data", error);
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const updatedAt = new Date().toISOString();
      await AddComment(articleId, nickname, comment, updatedAt, profileImage);
      window.location.reload();
      console.log("Comment submitted:", { nickname, comment, updatedAt });
    } catch (error) {
      console.error("Error submitting comment", error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box>
        <TextField
          className="gradient-textfield"
          required
          fullWidth
          variant='outlined'
          multiline
          rows={3}
          autoComplete="comment"
          name="comment"
          id="comment"
          autoFocus
          value={comment}
          onChange={handleCommentChange}
          sx={{ mb: 2 }}
          InputProps={{
            sx: {
              fontSize: '0.9rem',
            },
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          sx={{
            background: 'linear-gradient(45deg, rgba(80, 242, 196, 1), rgba(38, 208, 227, 1), rgba(117, 122, 255, 1))',
            "&:hover": {
              background: 'linear-gradient(45deg, rgba(80, 242, 196, 1), rgba(38, 208, 227, 1), rgba(117, 122, 255, 1))',
            },
            fontWeight: 550,
            letterSpacing: 2,
            mb: 2,
          }}
        >
          Submit
        </Button>
      </Box>
    </Container>
  );
}

export default CommentBox;
