import { Box, Container, IconButton, Typography, Snackbar, Alert, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/ChatBubbleOutline';
import AddLike from '../services/AddLike';
import UnLike from '../services/UnLike';
import Comment from './Comment';
import CommentBox from './CommentBox';

function LikeAndCommentSection({ articleId, articleLikeCount, articleLikes, articleComments }) {
  const [likeCount, setLikeCount] = useState(articleLikeCount);
  const [hasLiked, setHasLiked] = useState(false);
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCommentBox, setOpenCommentBox] = useState(false);

  useEffect(() => {
    const userId = sessionStorage.getItem('userId');
    setId(userId);
  }, []);

  useEffect(() => {
    if (id) {
      checkIfUserLiked();
    }
  }, [articleLikes, id]);

  const checkIfUserLiked = () => {
    const userHasLiked = articleLikes.some((like) => like.userId === id);
    setHasLiked(userHasLiked);
  };

  const handleLike = async () => {
    if (!hasLiked && id) {
      try {
        await AddLike(articleId, id);
        setLikeCount(likeCount + 1);
        setHasLiked(true);
      } catch (error) {
        console.error('Error liking the article:', error);
      }
    } else {
      setError('Please log in to like this article.');
      setOpen(true);
    }
  };

  const handleUnLike = async () => {
    if (hasLiked && id) {
      try {
        await UnLike(articleId, id);
        setLikeCount(likeCount - 1);
        setHasLiked(false);
      } catch (error) {
        console.error('Error unliking the article:', error);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const toggleCommentBox = () => {
    setOpenCommentBox((prev) => !prev);
  };

  return (
    <Container maxWidth="lg">
      <Box>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', mb: 3 }}>
          <IconButton
            onClick={hasLiked ? handleUnLike : handleLike}
            disableRipple={true}
            sx={{
              bgcolor: 'transparent',
              ':hover': {
                bgcolor: 'transparent',
                transition: 'none',
              },
            }}
          >
            <ThumbUpIcon color={hasLiked ? 'primary' : 'inherit'} />
            <Typography sx={{ ml: 1 }}>{likeCount}</Typography>
          </IconButton>

          <IconButton
            onClick={toggleCommentBox}
            disableRipple={true}
            sx={{ mt: { xs: 1, sm: 0 }, ml: { xs: 0, sm: 2 } }}
          >
            <CommentIcon />
            <Typography sx={{ ml: 1 }}>Comments</Typography>
          </IconButton>
        </Box>

        <Box sx={{ overflow: 'hidden', position: 'relative', minHeight: '180px' }}>
          <Slide direction="down" in={openCommentBox} mountOnEnter unmountOnExit>
            <Box sx={{ mt: 1, position: 'absolute', bottom: '0', width: '100%' }}>
              <CommentBox articleId={articleId} />
            </Box>
          </Slide>
        </Box>

        <Box
          my={-22}
          sx={{
            transform: openCommentBox ? 'translateY(150px)' : 'translateY(0)',
            transition: 'transform 0.3s ease-in-out',
          }}
        >
          <Comment data={articleComments} articleId={articleId} />
        </Box>
      </Box>
    </Container>
  );
}

export default LikeAndCommentSection;
