import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetArticleImage from '../services/GetArticleImage';
import Filter from './Filter';

function TravelGrid({ articles }) {
    const [articleImage, setArticleImage] = useState({});
    const [filteredArticles, setFilteredArticles] = useState(articles);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        const fetchImages = async () => {
            if (!Array.isArray(articles)) {
                console.error('Invalid articles data:', articles);
                return;
            }

            try {
                const images = await Promise.all(
                    articles.map(async (article) => {
                        const imageUrl = await GetArticleImage(article.articleImageKey);
                        return { [article.id]: imageUrl };
                    })
                );
                setArticleImage(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [articles]);

    useEffect(() => {
        const filtered = articles.filter((article) => {
            const articleName = article.title || "";
            const matchesSearch = searchQuery === "" || articleName.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesSearch;
        });
        setFilteredArticles(filtered);
    }, [searchQuery, articles]);

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    return (
        <Container maxWidth="xl">
            <Grid
                container
                spacing={2}
                mt={2}
                sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Grid item xs={12}>
                    <Filter onSearch={handleSearch} />
                </Grid>
                {filteredArticles.length > 0 ? (
                    filteredArticles.map((article) => (
                        <Grid
                            item
                            key={article.id}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            mb={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >

                            <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    height: "200px",
                                    width: "300px",
                                    position: "relative",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(1.02)",
                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="250"
                                    image={articleImage[article.id] || ""}
                                    alt={article.name || "Article Image"}
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        zIndex: 0,
                                    }}
                                    onClick={() => navigateToUrl(`/travel/${article.id}`)}
                                />

                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        height: "100%",
                                        width: "100%",
                                        background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                                        zIndex: 1,
                                        pointerEvents: "none",
                                    }}
                                />

                                <CardContent
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        width: "100%",
                                        zIndex: 2,
                                        color: "white",
                                        p: 2,
                                    }}
                                >
                                    <Typography fontFamily={'serif'} fontSize={"1.05rem"} sx={{ color: "white" }}>
                                        {article.title || "No Title"}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '500px',
                                width: '100%',
                                border: '1px solid',
                                borderImageSource: 'linear-gradient(45deg, rgba(80, 242, 196, 1), rgba(38, 208, 227, 1), rgba(117, 122, 255, 1))',
                                borderImageSlice: 1,
                            }}
                        >
                            <Typography fontFamily='serif' sx={{ fontSize: { xs: '0.9rem', sm: '1.5rem', md: '2.5rem', lg: '2.5rem' }, textAlign: 'center' }}>
                                We couldn’t find any travel articles based on your search.<br />Explore other categories or return later for fresh content
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

export default TravelGrid;
